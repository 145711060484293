import { Box } from '@mui/material';
import React, { Component, ReactNode } from 'react';
import QrScanner from 'qr-scanner';

type Props = {
  onDecode: (result: QrScanner.ScanResult) => void;
  onDecodeError?: ((error: string | Error) => void) | undefined;
};

const _onDecodeError: (error: string | Error) => void = (error) => {};

class Scanner extends Component<Props> {
  private scannerRef: QrScanner | null;
  private scannerState: 'STARTED' | null;
  constructor(props: Props) {
    super(props);
    this.scannerRef = null;
    this.scannerState = null;
  }
  componentDidMount(): void {
    const videoElement = document.getElementById('qr-video');
    if (videoElement) {
      this.scannerRef = new QrScanner(videoElement as HTMLVideoElement, this.props.onDecode, {
        preferredCamera: 'environment',
        maxScansPerSecond: 1,
        onDecodeError: this.props.onDecodeError ?? _onDecodeError,
        highlightScanRegion: true,
        highlightCodeOutline: true
      });
      if (this.scannerRef && this.scannerState === null) {
        this.scannerRef
          .start()
          .then((value) => {
            this.scannerState = 'STARTED';
          })
          .catch((error) => console.log('Scanner start ERROR:', error));
      }
    }
  }
  componentWillUnmount(): void {
    if (this.scannerRef && this.scannerState === 'STARTED') this.scannerRef.stop();
  }
  render(): ReactNode {
    return (
      <Box sx={{ backgroundColor: 'primary.main' }}>
        <video id='qr-video' style={{ width: '100%', maxWidth: '550px', height: '100%', maxHeight: '550px' }}></video>
      </Box>
    );
  }
}

export default React.memo(Scanner);
