import React, { useEffect, useRef, useState } from 'react';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Analytics, getAnalytics } from 'firebase/analytics';
import { Database, getDatabase } from 'firebase/database';
import { getFunctions } from 'firebase/functions';
import { MenuMode, ScanMode } from '../utils/types';
import { Firestore, getFirestore } from 'firebase/firestore';
import { Auth, getAuth, onAuthStateChanged, signInWithEmailAndPassword, User } from 'firebase/auth';
import ComponentLoader from '../components/common/ComponentLoader';

type GlobalCtxType = {
  firebaseApp: FirebaseApp;
  firebaseAnalytics: Analytics;
  firebaseDatabase: Database;
  fireStore: Firestore;
  auth: Auth;
  menuMode: MenuMode | undefined;
  setMenuMode: React.Dispatch<React.SetStateAction<MenuMode | undefined>>;
  scanMode: ScanMode | undefined;
  setScanMode: React.Dispatch<React.SetStateAction<ScanMode | undefined>>;
  scannedItems: string[];
  setScannedItems: React.Dispatch<React.SetStateAction<string[]>>;
  scannedItemsRef: React.MutableRefObject<string[]>;
  scanModeRef: React.MutableRefObject<ScanMode | undefined>;
  menuModeRef: React.MutableRefObject<MenuMode | undefined>;
};

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const firebaseAnalytics = getAnalytics(firebaseApp);
const firebaseDatabase = getDatabase(firebaseApp);
const fireStore = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const functions = getFunctions(firebaseApp);

const initialContext: GlobalCtxType = {
  firebaseApp,
  firebaseAnalytics,
  firebaseDatabase,
  fireStore,
  auth,
  menuMode: undefined,
  setMenuMode: () => {},
  scanMode: undefined,
  setScanMode: () => {},
  scannedItems: [],
  setScannedItems: () => {},
  scannedItemsRef: { current: [] },
  scanModeRef: { current: undefined },
  menuModeRef: { current: undefined }
};

const globalStateCtx = React.createContext<GlobalCtxType>(initialContext);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initItems = [
  'example-1-MB1',
  'example-1-MB2',
  'example-1-MB3',
  'example-1-MB4',
  'example-1-MB5',
  'example-2-MB1',
  'example-2-MB2'
];

const GlobalState = (props: { children: any }) => {
  const [menuMode, setMenuMode] = useState<MenuMode>();
  const [scanMode, setScanMode] = useState<ScanMode>();
  const [scannedItems, setScannedItems] = useState<string[]>([]);
  const scannedItemsRef = useRef<string[]>([]);
  const scanModeRef = useRef<ScanMode>();
  const menuModeRef = useRef<MenuMode>();
  const [user, setUser] = useState<User>();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        // User is signed out
        setUser(undefined);
        // Sign in again
        signInWithEmailAndPassword(auth, process.env.REACT_APP_USER_EMAIL!, process.env.REACT_APP_USER_PASSWORD!)
          .then((userCredential) => {
            // Signed in
            setUser(userCredential.user);
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log('Firebase Auth failed:', errorCode, errorMessage);
          });
      }
    });
    return unsubscribe;
  }, []);
  useEffect(() => {
    scannedItemsRef.current = scannedItems;
  }, [scannedItems]);
  useEffect(() => {
    scanModeRef.current = scanMode;
  }, [scanMode]);
  useEffect(() => {
    menuModeRef.current = menuMode;
  }, [menuMode]);
  console.log('GlobalState:', menuMode, scanMode);

  return (
    <globalStateCtx.Provider
      value={{
        firebaseApp,
        firebaseAnalytics,
        firebaseDatabase,
        fireStore,
        auth,
        menuMode,
        setMenuMode,
        scanMode,
        setScanMode,
        scannedItems,
        setScannedItems,
        scannedItemsRef,
        scanModeRef,
        menuModeRef
      }}
    >
      {user ? (
        props.children
      ) : (
        <div style={{ height: 'calc(var(--vh, 1vh) * 100)' }}>
          <ComponentLoader />
        </div>
      )}
    </globalStateCtx.Provider>
  );
};

export default GlobalState;
export const useGlobalState = () => React.useContext(globalStateCtx);
