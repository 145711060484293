import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getLocations, getFullTraceGraph } from '../../services/API';
import { Graph, Locations } from '../../utils/types';
import Map from '../map/Map';

const HomeScreen = () => {
  let [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [locations, setLocations] = useState<Locations>();
  const [data, setData] = useState<Graph>();

  const fetchData = async (epc: string) => {
    setLoading(true);
    try {
      const _data = await getFullTraceGraph(epc);
      setData(_data);
      const epcs = _data.nodes.map((n) => n.id);
      if (epcs.length > 0) {
        const _locations = await getLocations(epcs);
        setLocations(_locations);
        console.log('Data & Locations: ', _data, '\n', _locations);
      }
    } catch (e) {
      console.log('Data & Locations FAILED:', e);
      toast.error('Fel QR-kod!', { autoClose: 3000 });
      setLocations(undefined);
      setData(undefined);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const epc = searchParams.get('epc');
    if (epc) {
      fetchData(epc);
    } else {
      setLoading(false);
      setLocations(undefined);
      setData(undefined);
    }
  }, [searchParams]);

  return (
    <>
      <Map loading={loading} data={data} locations={locations} />
    </>
  );
};
export default HomeScreen;
