import { EPCToText } from './types';
import gris from './img/gris.png';
import gurka from './img/gurka.png';
import ko from './img/ko.png';
import kott from './img/kott.png';
import resturang from './img/resturang.png';
import bulle from './img/bulle.png';
import potatis from './img/potatis.png';
import skaladPotatis from './img/skaladPotatis.png';
import morot from './img/morot.png';
import agg from './img/agg.png';
import aggKartong from './img/aggKartong.png';
import hona from './img/hona.png';
import lok from './img/lok.png';

export const isMobileDevice = (): boolean => window.innerWidth <= 765;
export const retrieveEpc = (str: string): string => {
  let url;
  try {
    url = new URL(str);
  } catch (e) {
    console.log(e);
    return str;
  }
  const searchParams = url.searchParams.get('epc');
  const valid: boolean = url.protocol === 'http:' || url.protocol === 'https:';
  return valid && searchParams ? searchParams : str;
};
export const selectedItemText = (id: string): { text: string; link?: string } => {
  const idCode = id.split('-').pop();
  if (idCode) {
    if ((idCode as string).toLowerCase().endsWith('transformed')) {
      return {
        text: 'Ljuraskolan Norrlöping',
        link: 'https://norrkoping.se/skola-och-forskola/grundskola/kommunala-grundskolor/skolor/ljuraskolan'
      };
    }
    if (/^B1[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.B1.org, ...(EPCToText.B1.link && { link: EPCToText.B1.link }) };
    }
    if (/^B2[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.B2.org, ...(EPCToText.B2.link && { link: EPCToText.B2.link }) };
    }
    if (/^B3[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.B3.org, ...(EPCToText.B3.link && { link: EPCToText.B3.link }) };
    }
    if (/^B4[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.B4.org, ...(EPCToText.B4.link && { link: EPCToText.B4.link }) };
    }
    if (/^B5[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.B5.org, ...(EPCToText.B5.link && { link: EPCToText.B5.link }) };
    }
    if (/^C1[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.C1.org, ...(EPCToText.C1.link && { link: EPCToText.C1.link }) };
    }
    if (/^C2[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.C2.org, ...(EPCToText.C2.link && { link: EPCToText.C2.link }) };
    }
    if (/^C3[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.C3.org, ...(EPCToText.C3.link && { link: EPCToText.C3.link }) };
    }
    if (/^C4[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.C4.org, ...(EPCToText.C4.link && { link: EPCToText.C4.link }) };
    }
    if (/^C5[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.C5.org, ...(EPCToText.C5.link && { link: EPCToText.C5.link }) };
    }
    if (/^C6[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.C6.org, ...(EPCToText.C6.link && { link: EPCToText.C6.link }) };
    }
    if (/^C7[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.C7.org, ...(EPCToText.C7.link && { link: EPCToText.C7.link }) };
    }
    if (/^C8[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.C8.org, ...(EPCToText.C8.link && { link: EPCToText.C8.link }) };
    }
    if (/^CU[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.CU.org, ...(EPCToText.CU.link && { link: EPCToText.CU.link }) };
    }
    if (/^MB[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.MB.org, ...(EPCToText.MB.link && { link: EPCToText.MB.link }) };
    }
    if (/^PI1[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.PI1.org, ...(EPCToText.PI1.link && { link: EPCToText.PI1.link }) };
    }
    if (/^PI2[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.PI2.org, ...(EPCToText.PI2.link && { link: EPCToText.PI2.link }) };
    }
    if (/^PI3[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.PI3.org, ...(EPCToText.PI3.link && { link: EPCToText.PI3.link }) };
    }
    if (/^P1[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.P1.org, ...(EPCToText.P1.link && { link: EPCToText.P1.link }) };
    }
    if (/^PO[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.PO.org, ...(EPCToText.PO.link && { link: EPCToText.PO.link }) };
    }
    if (/^PP[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.PP.org, ...(EPCToText.PP.link && { link: EPCToText.PP.link }) };
    }
    if (/^CA[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.CA.org, ...(EPCToText.CA.link && { link: EPCToText.CA.link }) };
    }
    if (/^CH[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.CH.org, ...(EPCToText.CH.link && { link: EPCToText.CH.link }) };
    }
    if (/^E[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.E.org, ...(EPCToText.E.link && { link: EPCToText.E.link }) };
    }
    if (/^O[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.O.org, ...(EPCToText.O.link && { link: EPCToText.O.link }) };
    }
    if (/^PA[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.PA.org, ...(EPCToText.PA.link && { link: EPCToText.PA.link }) };
    }
    if (/^PE[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.PE.org, ...(EPCToText.PE.link && { link: EPCToText.PE.link }) };
    }
    if (/^SL[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.SL.org, ...(EPCToText.SL.link && { link: EPCToText.SL.link }) };
    }
    return { text: id };
  }
  return { text: 'N/A' };
};

export const getMapIcon = (id: string): any => {
  const idCode = id.split('-').pop();
  if (idCode) {
    if ((idCode as string).toLowerCase().endsWith('transformed')) {
      return resturang;
    }
    if (/^B1[0-9]*$/g.test(idCode)) {
      return kott;
    }
    if (/^B2[0-9]*$/g.test(idCode)) {
      return kott;
    }
    if (/^B3[0-9]*$/g.test(idCode)) {
      return kott;
    }
    if (/^B4[0-9]*$/g.test(idCode)) {
      return kott;
    }
    if (/^B5[0-9]*$/g.test(idCode)) {
      return kott;
    }
    if (/^C1[0-9]*$/g.test(idCode)) {
      return ko;
    }
    if (/^C2[0-9]*$/g.test(idCode)) {
      return ko;
    }
    if (/^C3[0-9]*$/g.test(idCode)) {
      return ko;
    }
    if (/^C4[0-9]*$/g.test(idCode)) {
      return ko;
    }
    if (/^C5[0-9]*$/g.test(idCode)) {
      return ko;
    }
    if (/^C6[0-9]*$/g.test(idCode)) {
      return ko;
    }
    if (/^C7[0-9]*$/g.test(idCode)) {
      return ko;
    }
    if (/^C8[0-9]*$/g.test(idCode)) {
      return ko;
    }
    if (/^CU[0-9]*$/g.test(idCode)) {
      return gurka;
    }
    if (/^MB[0-9]*$/g.test(idCode)) {
      return bulle;
    }
    if (/^P1[0-9]*$/g.test(idCode)) {
      return potatis;
    }
    if (/^PI1[0-9]*$/g.test(idCode)) {
      return gris;
    }
    if (/^PI2[0-9]*$/g.test(idCode)) {
      return gris;
    }
    if (/^PI3[0-9]*$/g.test(idCode)) {
      return gris;
    }
    if (/^PO[0-9]*$/g.test(idCode)) {
      return kott;
    }
    if (/^PP[0-9]*$/g.test(idCode)) {
      return skaladPotatis;
    }
    if (/^CA[0-9]*$/g.test(idCode)) {
      return morot;
    }
    if (/^CH[0-9]*$/g.test(idCode)) {
      return hona;
    }
    if (/^E[0-9]*$/g.test(idCode)) {
      return agg;
    }
    if (/^O[0-9]*$/g.test(idCode)) {
      return lok;
    }
    if (/^PA[0-9]*$/g.test(idCode)) {
      return aggKartong;
    }
    if (/^PE[0-9]*$/g.test(idCode)) {
      return skaladPotatis;
    }
    if (/^SL[0-9]*$/g.test(idCode)) {
      return kott;
    }
  }
  return undefined;
};
