import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Typography
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TransformIcon from '@mui/icons-material/Transform';

import PrintIcon from '@mui/icons-material/Print';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CustomNavigateFunction, CustomState, MenuMode, pathTo, ScanMode } from '../../utils/types';
import { useGlobalState } from '../../context/GlobalState';
import { MeatIcon, SaladIcon, FishIcon } from '../../utils/img/CustomSvgIcons';
import { isMobileDevice } from '../../utils/HelperFunctions';
import { Map, QrCode2 } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PrintModal from './PrintModal';

type Props = {
  anchor?: 'right' | 'left' | 'top' | 'bottom' | undefined;
  open: boolean;

  toggleMenu: (b?: boolean) => void;
};

const SideMenu = (props: Props) => {
  const navigate = useNavigate() as CustomNavigateFunction;
  const location = useLocation();
  const globalState = useGlobalState();
  const [searchParams] = useSearchParams();

  const [epcCode, setEpcCode] = useState<string>();
  const [printModalOpen, setPrintModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const epc = searchParams.get('epc');
    if (epc) {
      setEpcCode(epc);
    } else {
      setEpcCode(undefined);
    }
  }, [searchParams]);

  const handleClick = (path: pathTo, rest: CustomState) => {
    console.log('Location:', location);
    globalState.setScanMode(rest.scanMode);
    globalState.setMenuMode(rest.menuMode);
    props.toggleMenu(false);
    if (location.pathname !== '/scanner') {
      navigate(path, { state: { prevPage: rest.prevPage }, replace: true });
    }
  };

  const printMap = () => {
    console.log('PrintMapCalled:');
    toast.info(
      <p>
        Fungerar inte <br /> använd Ctr/⌘ + p
      </p>,
      { autoClose: 3500 }
    );
  };
  return (
    <SwipeableDrawer
      anchor={props.anchor}
      open={props.open}
      onClose={() => props.toggleMenu(false)}
      onOpen={() => props.toggleMenu(true)}
      PaperProps={isMobileDevice() ? { sx: { width: '65%' } } : {}}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}
      >
        <IconButton
          onClick={() => props.toggleMenu(false)}
          size='large'
          edge='end'
          color='inherit'
          aria-label='menu'
          sx={{ marginRight: '1.5rem' }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box sx={{ flexGrow: 1 }}>
        <List>
          <ListItem disablePadding>
            <Accordion elevation={0} sx={{ width: '100%' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                <ListItemIcon>{<TransformIcon />}</ListItemIcon>
                <Typography>Skapa</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ListItemButton
                  onClick={() =>
                    handleClick('/scanner', { prevPage: '/', scanMode: ScanMode.TRANSFORM, menuMode: MenuMode.MEAT })
                  }
                >
                  <ListItemIcon sx={{ justifyContent: 'center', marginBottom: 2, marginLeft: -3, marginRight: 3 }}>
                    <MeatIcon sx={{ width: '1rem', height: '.2rem', overflow: 'visible' }} />
                  </ListItemIcon>
                  <ListItemText primary={'Köttmeny'} />
                </ListItemButton>
                <ListItemButton
                  onClick={() =>
                    handleClick('/scanner', { prevPage: '/', scanMode: ScanMode.TRANSFORM, menuMode: MenuMode.FISH })
                  }
                >
                  <ListItemIcon sx={{ justifyContent: 'center', marginBottom: 2, marginLeft: -3, marginRight: 3 }}>
                    {<FishIcon sx={{ width: '1rem', height: '.2rem', overflow: 'visible', marginRight: '.5rem' }} />}
                  </ListItemIcon>
                  <ListItemText primary={'Fiskmeny'} />
                </ListItemButton>
                <ListItemButton
                  onClick={() =>
                    handleClick('/scanner', {
                      prevPage: '/',
                      scanMode: ScanMode.TRANSFORM,
                      menuMode: MenuMode.VEGETARIAN
                    })
                  }
                >
                  <ListItemIcon sx={{ justifyContent: 'center', marginBottom: 1, marginLeft: -3, marginRight: 3 }}>
                    <SaladIcon sx={{ width: '10px', height: '10px', overflow: 'visible' }} />
                  </ListItemIcon>
                  <ListItemText primary={'Veganskmeny'} />
                </ListItemButton>
              </AccordionDetails>
            </Accordion>
          </ListItem>

          {epcCode && (
            <>
              <ListItem disablePadding>
                <Accordion elevation={0} sx={{ width: '100%' }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                    <ListItemIcon>{<PrintIcon />}</ListItemIcon>
                    <Typography>Skriv ut</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ListItemButton
                      onClick={() => {
                        setPrintModalOpen(true);
                      }}
                    >
                      <ListItemIcon>
                        <QrCode2 />
                      </ListItemIcon>
                      <ListItemText primary={'Skriv ut QR-Kod'} />
                    </ListItemButton>
                    <ListItemButton onClick={() => printMap()}>
                      <ListItemIcon>
                        <Map />
                      </ListItemIcon>
                      <ListItemText primary={'Skriv ut karta'} />
                    </ListItemButton>
                  </AccordionDetails>
                </Accordion>
              </ListItem>
              <PrintModal printModalOpen={printModalOpen} setPrintModalOpen={setPrintModalOpen} epcCode={epcCode} />
            </>
          )}
        </List>
      </Box>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>{<LogoutIcon />}</ListItemIcon>
            <ListItemText primary={'Logga ut'} />
          </ListItemButton>
        </ListItem>
      </List>
    </SwipeableDrawer>
  );
};

export default SideMenu;
