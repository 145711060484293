import './AppShell.scss';
import { useState } from 'react';
import brand from '../../utils/img/brand.png';
//____MUI Imports____
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Tooltip from '@mui/material/Tooltip';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import SideMenu from './SideMenu';

const AppShell = (props: { children?: any }) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = (b?: boolean) => {
    if (b) {
      setMenuOpen(b);
    } else {
      setMenuOpen(!menuOpen);
    }
  };

  /**Receives the container ID  e.g. ink-qr-container  */

  return (
    <div className='appShellContainer'>
      <AppBar position='static' className='appBarStyle'>
        <Toolbar>
          {location.state && location.state.prevPage && (
            <Tooltip title='Go back'>
              <IconButton
                size='large'
                edge='start'
                color='inherit'
                aria-label='back'
                sx={{ flexGrow: 0 }}
                onClick={() => navigate(location.state.prevPage, { replace: true })}
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title='Go to Home'>
            <IconButton
              color='inherit'
              aria-label='brand'
              sx={{ flexGrow: 1 }}
              onClick={() => {
                navigate('/', { replace: true });
              }}
            >
              <img src={brand} alt='brand' style={{ height: '1.5rem' }} />
            </IconButton>
          </Tooltip>

          <Tooltip title='Open menu'>
            <IconButton
              onClick={() => toggleMenu(true)}
              size='large'
              edge='end'
              color='inherit'
              aria-label='menu'
              sx={{ mr: 0.5, flexGrow: 0 }}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>

          <SideMenu anchor='right' open={menuOpen} toggleMenu={toggleMenu} />
        </Toolbar>
      </AppBar>
      <Outlet />
    </div>
  );
};

export default AppShell;
