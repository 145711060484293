import { SvgIcon, SvgIconProps } from '@mui/material';

export const FishIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d='M147.274,66.682c-12.088-12.088-20.509-26.597-23.713-40.853c-0.652-2.901-1.073-5.732-1.28-8.484
	c-7.548,3.763-14.94,8.326-21.833,13.879l-33.467-5.027c-5.106-0.766-9.954,2.478-11.193,7.488l-12.24,49.48
	c-0.653,2.642-0.202,5.435,1.25,7.736s3.778,3.912,6.443,4.46l8.703,1.79c-2.109,7.721-3.568,15.147-4.572,21.894l-42.779,9.635
	c-6.288,1.416-10.987,6.221-12.264,12.539s1.189,12.57,6.434,16.316c0.83,0.593,1.745,1.055,2.715,1.371l28.316,9.226
	c3.791,1.235,6.791,4.235,8.026,8.025l9.227,28.317c0.315,0.969,0.778,1.884,1.37,2.714c3.067,4.294,7.838,6.757,13.09,6.757
	c0,0,0,0,0,0c7.612,0,14.096-5.176,15.765-12.586l9.642-42.812c4.78-0.713,9.89-1.651,15.198-2.882l-0.366,7.815
	c-0.293,6.256,3.935,11.769,10.051,13.106l10.762,2.356c0.9,0.197,1.823,0.297,2.742,0.297c6.803,0,12.417-5.324,12.78-12.123
	l1.331-25.001c9.84-5.171,19.344-11.763,27.747-20.167c9.128-9.128,16.118-19.541,21.47-30.261
	C180.56,90.542,162.511,81.919,147.274,66.682z M142.544,9.111c24.957-8.121,48.351-9.102,57.971-9.102
	c2.338,0,3.689,0.059,3.835,0.066c5.162,0.237,9.293,4.369,9.53,9.531c0.085,1.845,1.194,30.526-8.992,61.799
	c-4.529,0.844-9.249,0.18-12.377-0.524c-10.455-2.349-21.788-9.034-31.095-18.341c-9.308-9.307-15.993-20.641-18.342-31.095
	C142.374,18.326,141.713,13.626,142.544,9.111z M161.674,26.015c1.403,2.355,3.894,3.662,6.45,3.662
	c1.306,0,2.628-0.341,3.832-1.058l0.896-0.534c3.559-2.12,4.724-6.724,2.604-10.282s-6.723-4.725-10.282-2.604l-0.896,0.534
	C160.72,17.853,159.554,22.457,161.674,26.015z'
    />
  </SvgIcon>
);

export const MeatIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d='M191.192,43.137c-0.107-4.907-1.603-9.94-4.721-14.391c-5.474-7.834-13.97-12.104-23.691-11.534
			c-12.158,0.756-27.56,5.953-42.455,10.975l-7.405,2.48c-6.121,2.036-11.972,2.326-19.38,2.695
			c-9.618,0.479-21.574,1.068-38.429,5.941c-25.421,7.364-42.966,20.081-50.72,36.764c-2.883,6.186-4.24,12.852-4.286,19.678
			C0.087,95.763,0,95.843,0,95.843v20.67c-0.038,7.466,1.611,15.148,4.931,22.639c8.893,19.993,34.636,35.072,59.889,35.072
			c17.61,0,33.963-6.977,47.293-20.185c11.67-11.554,15.861-19,20.33-26.878c3.09-5.458,6.255-11.085,12.451-18.631
			c5.306-6.47,12.098-9.632,19.287-12.978c10.184-4.743,20.715-9.648,25.916-23.888c0.95-2.631,1.371-5.448,1.238-8.299
			l0.049-20.025C191.325,43.245,191.241,43.197,191.192,43.137z M13.762,80.426c6.464-13.896,21.754-24.682,44.22-31.19
			c15.693-4.546,26.507-5.079,36.059-5.558c7.875-0.385,14.683-0.729,22.129-3.206l7.454-2.495
			c14.258-4.811,29.001-9.79,39.793-10.458l1.082-0.03c5.398,0,10.304,2.615,13.493,7.175c2.85,4.076,3.763,8.966,2.385,12.757
			c-3.735,10.211-11.157,13.68-20.578,18.062c-7.542,3.519-16.098,7.506-22.906,15.797c-6.745,8.233-10.167,14.262-13.461,20.111
			c-4.256,7.546-7.951,14.062-18.605,24.61c-11.353,11.261-25.183,17.195-40.014,17.195c-23.52,0-44.12-14.719-50.438-28.934
			C10.734,106.046,7.671,93.537,13.762,80.426z'
    />
    <path
      d='M64.821,132.848c12.216,0,23.229-4.77,32.734-14.207c9.618-9.514,12.82-15.166,16.86-22.319
			c3.357-5.949,7.152-12.671,14.49-21.61c8.3-10.113,18.422-14.824,26.541-18.607c9.795-4.57,13.145-6.524,15.217-12.232
			c0.092-0.399-0.133-1.859-1.143-3.288c-0.721-1.036-2.344-2.771-5.013-2.771c-9.875,0.605-23.953,5.354-37.55,9.946l-7.514,2.519
			c-8.801,2.921-16.595,3.308-24.862,3.723c-9.021,0.447-19.194,0.958-33.717,5.158C41.349,64.81,28.301,73.664,23.127,84.772
			c-4.45,9.588-2.078,19.045,0.701,25.284C28.7,121.021,45.761,132.848,64.821,132.848z M63.905,75.477
			c13.469,0,24.389,7.662,24.389,17.132c0,9.452-10.92,17.127-24.389,17.127c-13.481,0-24.403-7.676-24.403-17.127
			C39.502,83.139,50.424,75.477,63.905,75.477z'
    />
  </SvgIcon>
);
export const SaladIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d='M49,21.605c0,0.553,0.447,1,1,1h4c0.553,0,1-0.447,1-1s-0.447-1-1-1h-4C49.447,20.605,49,21.052,49,21.605z' />
    <path
      d='M30.5,22.105c0.256,0,0.512-0.098,0.707-0.293l1.5-1.5c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0l-1.5,1.5
		c-0.391,0.391-0.391,1.023,0,1.414C29.988,22.007,30.244,22.105,30.5,22.105z'
    />
    <path
      d='M35.293,24.312c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414l-1-1
		c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414L35.293,24.312z'
    />
    <path
      d='M31.293,23.898l-1,1c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l1-1
		c0.391-0.391,0.391-1.023,0-1.414S31.683,23.507,31.293,23.898z'
    />
    <path
      d='M58.565,28.357c-0.447-0.478-1.077-0.752-1.729-0.752h-0.194c0.879-1.513,1.357-3.231,1.357-5
		c0-3.989-2.386-7.58-6.008-9.158C51.906,8.007,47.46,3.605,42,3.605c-5.459,0-9.904,4.399-9.992,9.837
		c-0.204-0.048-0.414-0.076-0.627-0.076c-0.89,0-1.726,0.434-2.238,1.16l-0.249,0.354c-0.199,0.283-0.597,0.392-0.912,0.245
		l-0.392-0.182c-0.366-0.17-0.753-0.256-1.151-0.256c-1.426,0-2.598,1.072-2.726,2.492l-0.039,0.431
		c-0.014,0.154-0.082,0.369-0.171,0.599c-0.229-0.11-0.393-0.333-0.417-0.6l-0.039-0.429c-0.075-0.834-0.519-1.54-1.162-1.985
		l3.141-4.458c1.271-1.804,0.837-4.304-0.966-5.574c-0.675-0.476-1.469-0.727-2.297-0.727c-1.305,0-2.53,0.633-3.277,1.692
		l-5.76,8.175c-0.195,0.276-0.336,0.572-0.452,0.875c-0.103,0.004-0.204-0.012-0.293-0.053l-0.392-0.182
		c-0.366-0.169-0.752-0.255-1.15-0.255c-1.426,0-2.598,1.071-2.727,2.491l-0.039,0.431c-0.032,0.355-0.313,0.636-0.668,0.668
		l-0.43,0.039c-0.885,0.08-1.68,0.588-2.124,1.357c-0.445,0.771-0.487,1.712-0.113,2.521l0.181,0.39
		c0.15,0.324,0.047,0.708-0.245,0.913l-0.353,0.249c-0.727,0.513-1.161,1.35-1.161,2.239c0,0.593,0.208,1.153,0.552,1.618H2.373
		c-0.652,0-1.282,0.274-1.729,0.752c-0.451,0.482-0.684,1.138-0.639,1.797C0.597,38.9,6.161,43.292,14.334,47.195
		c0.912,0.436,1.665,1.16,2.122,2.042c0.446,0.862,0.375,1.974-0.194,2.92c-0.429,0.712-0.441,1.569-0.032,2.293
		c0.409,0.724,1.149,1.155,1.98,1.155H41c0.831,0,1.571-0.432,1.98-1.155c0.409-0.724,0.397-1.581-0.042-2.311
		c-0.559-0.929-0.629-2.04-0.184-2.901c0.456-0.882,1.209-1.607,2.122-2.043c8.171-3.903,13.736-8.294,14.329-17.041
		C59.249,29.494,59.015,28.839,58.565,28.357z M56,22.605c0,1.82-0.625,3.579-1.768,5H49v-4c0-0.553-0.447-1-1-1s-1,0.447-1,1v4
		h-3.531c0.081-0.11,0.151-0.226,0.214-0.346c0.003-0.005,0.007-0.01,0.01-0.015c0.088-0.17,0.151-0.352,0.202-0.537
		c0.013-0.046,0.024-0.092,0.034-0.14c0.041-0.19,0.07-0.383,0.07-0.581c0-0.89-0.434-1.726-1.16-2.236l-0.23-0.163
		c0.08-0.005,0.157-0.018,0.236-0.024c0.12-0.01,0.24-0.022,0.359-0.037c0.197-0.024,0.392-0.054,0.585-0.089
		c0.114-0.021,0.229-0.04,0.342-0.065c0.208-0.045,0.413-0.101,0.616-0.159c0.091-0.026,0.183-0.047,0.273-0.076
		c0.285-0.091,0.564-0.192,0.837-0.307c0.076-0.032,0.148-0.07,0.222-0.104c0.198-0.089,0.395-0.181,0.586-0.283
		c0.107-0.057,0.211-0.119,0.315-0.18c0.153-0.089,0.305-0.179,0.453-0.276c0.11-0.072,0.218-0.146,0.325-0.222
		c0.135-0.096,0.268-0.196,0.398-0.298c0.107-0.084,0.213-0.167,0.316-0.255c0.123-0.105,0.24-0.215,0.358-0.325
		c0.101-0.094,0.203-0.187,0.3-0.285c0.111-0.113,0.214-0.233,0.319-0.351c0.199-0.223,0.387-0.454,0.566-0.695
		c0.063-0.085,0.13-0.167,0.191-0.255c0.109-0.157,0.209-0.32,0.309-0.482c0.05-0.082,0.102-0.163,0.15-0.247
		c0.101-0.175,0.194-0.354,0.284-0.535c0.035-0.072,0.07-0.143,0.104-0.216c0.093-0.199,0.18-0.4,0.26-0.606
		c0.017-0.044,0.033-0.09,0.049-0.134c0.181-0.487,0.335-0.987,0.439-1.505C54.355,16.957,56,19.644,56,22.605z M22,46.605h3.386
		c0.552,0,1,0.447,1,1s-0.448,1-1,1H22c-0.552,0-1-0.447-1-1S21.447,46.605,22,46.605z M39.087,17.61l-0.039-0.429
		c-0.05-0.554-0.262-1.051-0.582-1.456l1.413-1.413c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414l-2.137,2.136
		C39.119,17.785,39.095,17.7,39.087,17.61z M42.707,11.484l2.829-2.828c0.391-0.391,1.023-0.391,1.414,0
		c0.391,0.391,0.39,1.023,0,1.414l-2.829,2.828c-0.195,0.195-0.451,0.293-0.707,0.293s-0.512-0.098-0.707-0.293
		C42.316,12.507,42.316,11.875,42.707,11.484z M42.707,14.312c0.391-0.391,1.023-0.391,1.414,0l2.829,2.828
		c0.39,0.391,0.391,1.023,0,1.414c-0.195,0.195-0.451,0.293-0.707,0.293s-0.512-0.098-0.707-0.293l-2.829-2.828
		C42.316,15.335,42.316,14.703,42.707,14.312z M37.05,8.656c0.391-0.391,1.023-0.391,1.414,0l2.829,2.828
		c0.391,0.391,0.391,1.023,0,1.414c-0.195,0.195-0.451,0.293-0.707,0.293s-0.512-0.098-0.707-0.293L37.05,10.07
		C36.659,9.679,36.659,9.046,37.05,8.656z M25.666,17.791l0.039-0.431c0.051-0.577,0.629-0.797,1.043-0.603l0.394,0.183
		c1.173,0.541,2.645,0.144,3.386-0.908l0.249-0.354c0.28-0.398,0.926-0.397,1.206-0.001l0.249,0.354
		c0.411,0.583,1.046,0.955,1.729,1.088c0.019,0.004,0.038,0.007,0.057,0.01c0.111,0.019,0.221,0.033,0.334,0.039
		c0.051,0.003,0.101,0.002,0.151,0.002c0.089,0,0.177,0,0.265-0.009c0.081-0.007,0.16-0.023,0.239-0.038
		c0.063-0.012,0.126-0.019,0.188-0.036c0.145-0.038,0.287-0.086,0.425-0.149l0.391-0.182c0.409-0.19,0.992,0.025,1.043,0.604
		l0.039,0.429c0.12,1.318,1.163,2.362,2.48,2.48l0.43,0.039c0.243,0.021,0.452,0.155,0.573,0.366
		c0.122,0.21,0.133,0.458,0.031,0.677l-0.184,0.396c-0.009,0.02-0.012,0.037-0.02,0.056c-0.354,0.815-0.28,1.72,0.142,2.453
		c0.006,0.01,0.012,0.021,0.018,0.031c0.054,0.091,0.111,0.178,0.176,0.263c0.035,0.047,0.074,0.09,0.112,0.135
		c0.049,0.056,0.096,0.114,0.15,0.166c0.102,0.101,0.211,0.196,0.331,0.28l0.355,0.251C41.886,25.523,42,25.743,42,25.986
		s-0.114,0.462-0.312,0.602l-0.356,0.25c-0.003,0.002-0.005,0.005-0.009,0.008c-0.13,0.093-0.249,0.198-0.36,0.312
		c-0.044,0.045-0.082,0.096-0.123,0.144c-0.06,0.071-0.119,0.143-0.171,0.22c-0.02,0.029-0.046,0.054-0.065,0.084H27.469
		c0.081-0.11,0.151-0.227,0.215-0.347c0.003-0.005,0.006-0.009,0.009-0.014c0.088-0.171,0.152-0.353,0.203-0.539
		c0.013-0.046,0.023-0.091,0.033-0.137c0.042-0.189,0.07-0.382,0.071-0.579l0-0.002l0,0l0,0c0-0.128-0.028-0.25-0.045-0.375
		c-0.013-0.092-0.011-0.187-0.034-0.277c-0.041-0.167-0.11-0.325-0.181-0.481c-0.019-0.041-0.027-0.086-0.047-0.126
		c-0.012-0.022-0.03-0.04-0.042-0.062c-0.198-0.357-0.468-0.675-0.81-0.916l-0.355-0.25c-0.291-0.205-0.393-0.588-0.244-0.91
		l0.183-0.393c0.089-0.192,0.15-0.393,0.192-0.597c0.013-0.061,0.013-0.122,0.021-0.183c0.02-0.144,0.035-0.287,0.032-0.431
		c-0.002-0.075-0.014-0.149-0.022-0.224c-0.014-0.129-0.031-0.257-0.063-0.383c-0.021-0.082-0.049-0.16-0.077-0.24
		c-0.024-0.066-0.036-0.135-0.064-0.2c-0.017-0.039-0.047-0.068-0.066-0.105c-0.025-0.051-0.038-0.106-0.066-0.156
		c-0.053-0.092-0.126-0.167-0.189-0.251c-0.051-0.069-0.097-0.141-0.154-0.205c-0.122-0.135-0.26-0.251-0.405-0.359
		c-0.042-0.031-0.074-0.076-0.119-0.105C25.548,18.454,25.636,18.12,25.666,17.791z M5.427,26.837l-0.354-0.249
		c-0.199-0.14-0.313-0.359-0.313-0.603s0.114-0.463,0.313-0.604l0.351-0.248c1.083-0.761,1.465-2.186,0.909-3.39l-0.181-0.39
		c-0.103-0.221-0.091-0.469,0.03-0.68c0.122-0.21,0.33-0.344,0.573-0.365l0.429-0.039c1.318-0.118,2.361-1.161,2.481-2.479
		l0.039-0.431c0.051-0.577,0.63-0.793,1.043-0.603l0.394,0.183c0.285,0.132,0.586,0.21,0.897,0.234
		c0.002,0.017,0.01,0.033,0.013,0.051c0.026,0.165,0.074,0.324,0.12,0.485c0.027,0.093,0.043,0.189,0.077,0.28
		c0.06,0.165,0.144,0.32,0.227,0.476c0.041,0.078,0.07,0.16,0.116,0.235c0.102,0.167,0.229,0.32,0.357,0.472
		c0.045,0.053,0.079,0.114,0.127,0.165c0.184,0.196,0.389,0.377,0.617,0.538c0.169,0.119,0.345,0.224,0.527,0.314
		c0.547,0.271,1.149,0.413,1.77,0.413h0c0,0,0,0,0,0c1.305,0,2.529-0.633,3.276-1.692l1.471-2.088
		c0.167,0.111,0.296,0.291,0.318,0.537l0.039,0.429c0.12,1.318,1.163,2.361,2.48,2.48l0.43,0.039
		c0.094,0.008,0.176,0.047,0.256,0.086c0.039,0.02,0.078,0.038,0.113,0.065c0.054,0.04,0.098,0.092,0.139,0.146
		c0.026,0.034,0.055,0.065,0.075,0.104c0.048,0.094,0.082,0.193,0.087,0.295c0,0.004-0.001,0.01-0.001,0.014
		c0.004,0.112-0.017,0.226-0.066,0.332l-0.183,0.393c-0.209,0.451-0.285,0.933-0.242,1.4c0.001,0.006,0.003,0.012,0.003,0.019
		c0.015,0.149,0.04,0.296,0.079,0.441c0.161,0.602,0.527,1.148,1.067,1.528l0.243,0.171l0.111,0.078
		C25.886,25.524,26,25.743,26,25.986c0,0.122-0.028,0.237-0.082,0.34c-0.008,0.015-0.025,0.024-0.033,0.038
		c-0.052,0.084-0.113,0.165-0.198,0.225l-0.079,0.056l-0.275,0.193c-0.137,0.097-0.263,0.209-0.379,0.329
		c-0.036,0.037-0.065,0.079-0.098,0.117c-0.076,0.088-0.149,0.178-0.214,0.275c-0.011,0.016-0.026,0.03-0.036,0.046H6.156
		C5.965,27.305,5.718,27.042,5.427,26.837z M37,51.605H22c-0.552,0-1-0.447-1-1s0.448-1,1-1h15c0.552,0,1,0.447,1,1
		S37.552,51.605,37,51.605z M37,48.605h-8.707c-0.552,0-1-0.447-1-1s0.448-1,1-1H37c0.552,0,1,0.447,1,1S37.552,48.605,37,48.605z'
    />
  </SvgIcon>
);
