import { GoogleMap, InfoBox, Marker, MarkerF, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useEffect, useState } from 'react';
import { mapStyle } from './MapStyle';
import './Map.scss';
import Box from '@mui/material/Box';
import { IconButton, Link as MUILink, Typography } from '@mui/material';
import MapOverlay from './MapOverlay';
import { Graph, Locations } from '../../utils/types';

import markerPerson from '../../utils/img/markerPerson.png';
import { getMapIcon, isMobileDevice, selectedItemText } from '../../utils/HelperFunctions';
import CloseIcon from '@mui/icons-material/HighlightOff';
import { grey } from '@mui/material/colors';
import { ClusterIconStyle } from '@react-google-maps/marker-clusterer';
import ComponentLoader, { componentLoaderStyles } from '../common/ComponentLoader';

type Props = { loading: boolean; data?: Graph; locations?: Locations; disableOverLay?: boolean };
type Libraries = ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[];
const libraries: Libraries = ['drawing'];

const Map = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [selectedItem, setSelectedItem] = useState<string>();
  const [satellite, setSatellite] = useState<boolean>(false);
  const [userPosition, setUserPosition] = useState<GeolocationCoordinates>();

  useEffect(() => {
    const id = navigator.geolocation.watchPosition(
      (position) => {
        setUserPosition(position.coords);
      },
      (error) => {
        console.log('Position watch error:', error);
      },
      { enableHighAccuracy: false, timeout: 5000, maximumAge: 0 }
    );
    return () => {
      navigator.geolocation.clearWatch(id);
    };
  }, []);

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
    libraries
  });

  const onLoad = useCallback((map: google.maps.Map) => {
    let defaultLocation = { lat: 59.2781126, lng: 15.1674353 };
    //Set the bounds to the furthest north and south points on the map in order to avoid zooming out or panning further than those bounds
    const maxBounds = new google.maps.LatLngBounds(new google.maps.LatLng(-85, -180), new google.maps.LatLng(85, 180));

    map.setOptions({
      center: defaultLocation,
      zoom: 7,
      controlSize: 30,
      restriction: {
        latLngBounds: maxBounds,
        strictBounds: true
      },
      fullscreenControl: false,
      mapTypeControl: false,
      styles: mapStyle
    });

    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (position) {
          const currentLocation = { lat: position.coords.latitude, lng: position.coords.longitude };
          map.setCenter(currentLocation);
        }
      },
      (error) => console.warn('Browser location error', error)
    );

    setMap(map);
  }, []);

  const onUnmount = useCallback((map: google.maps.Map) => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (map) {
      map.setOptions({ mapTypeId: satellite ? google.maps.MapTypeId.HYBRID : google.maps.MapTypeId.ROADMAP });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [satellite]);

  return loadError ? (
    <Box sx={componentLoaderStyles.fullCentered}>
      <Typography variant='h6' color={grey[100]}>
        Map Loading Error!
      </Typography>
      <Typography variant='body1' color={grey[100]}>
        Please refresh the page!
      </Typography>
    </Box>
  ) : !isLoaded ? (
    <ComponentLoader />
  ) : (
    <GoogleMap
      id='react-google-maps'
      mapContainerClassName={'google-map-container-style'}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onZoomChanged={() => {}}
    >
      {props.locations &&
        Object.entries(props.locations!.allLocations!).map(([id, [lng, lat]], index) => (
          <MarkerF
            key={index}
            position={{ lat, lng }}
            icon={{ scaledSize: new google.maps.Size(45, 55), url: getMapIcon(id) }}
            onClick={() => setSelectedItem(id)}
          >
            {selectedItem && selectedItem === id && (
              <InfoBox
                options={{
                  enableEventPropagation: true,
                  closeBoxURL: '',
                  boxStyle: {
                    boxShadow: ' 8px 8px 41px -15px rgba(0,0,0,0.75)',
                    backgroundColor: 'rgba(255,255,255,0)'
                  }
                }}
              >
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'white',
                      padding: '1.5rem .5rem',
                      borderRadius: '1rem',
                      minWidth: isMobileDevice() ? 'calc(window.innerWidth - 2rem)' : '25%'
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      {selectedItemText(id).link ? (
                        <MUILink
                          href={selectedItemText(id).link}
                          target='_blank'
                          color={'secondary'}
                          sx={{ fontSize: '14px' }}
                          noWrap
                        >
                          {selectedItemText(id).text}
                        </MUILink>
                      ) : (
                        <Typography variant='body2' align='left' noWrap sx={{ fontSize: '14px' }}>
                          {selectedItemText(id).text}
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <IconButton
                        aria-label='close button'
                        onClick={() => setSelectedItem(undefined)}
                        sx={{ padding: 0, marginLeft: 2 }}
                      >
                        <CloseIcon sx={{ color: grey[600] }} fontSize='small' />
                      </IconButton>
                    </Box>
                  </Box>
                </>
              </InfoBox>
            )}
          </MarkerF>
        ))}

      {userPosition && (
        <Marker
          position={{ lat: userPosition!.latitude, lng: userPosition!.longitude }}
          icon={{ url: markerPerson, scaledSize: new google.maps.Size(40, 40) }}
          onClick={() => {
            setSelectedItem('userLocation');
          }}
        >
          {selectedItem && selectedItem === 'userLocation' && (
            <InfoBox
              options={{
                enableEventPropagation: true,
                closeBoxURL: '',
                boxStyle: {
                  boxShadow: ' 8px 8px 41px -15px rgba(0,0,0,0.75)',
                  backgroundColor: 'rgba(255,255,255,0)'
                }
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  padding: '1.5rem .5rem',
                  borderRadius: '1rem',
                  minWidth: isMobileDevice() ? 'calc(window.innerWidth - 2rem)' : '25%'
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant='body2' align='left' noWrap>
                    Du är här
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    aria-label='close button'
                    onClick={() => setSelectedItem(undefined)}
                    sx={{ padding: 0, marginLeft: 2 }}
                  >
                    <CloseIcon sx={{ color: grey[600] }} fontSize='small' />
                  </IconButton>
                </Box>
              </Box>
            </InfoBox>
          )}
        </Marker>
      )}

      {props.locations &&
        props.data &&
        props.data!.edges.map(([source, target], index) =>
          props.locations!.allLocations[source] && props.locations!.allLocations[target] ? (
            <Polyline
              key={index}
              options={{
                strokeColor: '#4468E1',
                icons: [
                  {
                    icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
                    offset: '50%'
                  }
                ]
              }}
              path={[
                { lat: props.locations!.allLocations[source][1], lng: props.locations!.allLocations[source][0] },
                { lat: props.locations!.allLocations[target][1], lng: props.locations!.allLocations[target][0] }
              ]}
            />
          ) : (
            <></>
          )
        )}

      <MapOverlay
        map={map}
        loading={props.loading}
        satellite={satellite}
        setSatellite={setSatellite}
        disableOverlay={props.disableOverLay}
      />
    </GoogleMap>
  );
};
export default Map;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MarkerClustererStyles: ClusterIconStyle[] = [
  { height: 70, width: 70, url: require('../../utils/img/m1.png'), textColor: 'white', textSize: 12 },
  { height: 75, width: 75, url: require('../../utils/img/m2.png'), textColor: 'black', textSize: 12 },
  { height: 80, width: 80, url: require('../../utils/img/m3.png'), textColor: 'white', textSize: 13 },
  { height: 85, width: 85, url: require('../../utils/img/m4.png'), textColor: 'black', textSize: 13 },
  { height: 90, width: 90, url: require('../../utils/img/m5.png'), textColor: 'white', textSize: 14 }
];
