import { AppBar, Box, IconButton, LinearProgress, Toolbar, Tooltip } from '@mui/material';

import { doc, getDoc, Timestamp } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalState } from '../../context/GlobalState';

import brand from '../../utils/img/brand.png';
import { getFullTraceGraph, getLocations } from '../../services/API';
import { Graph, Locations } from '../../utils/types';
import { toast } from 'react-toastify';
import Map from '../map/Map';

const RestaurantMapScreen = () => {
  const navigate = useNavigate();
  const params = useParams<{ restaurantID: string; menuID: string }>();
  const globalState = useGlobalState();
  const [epc, setEpc] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [locations, setLocations] = useState<Locations>();
  const [data, setData] = useState<Graph>();
  useEffect(() => {
    if (params.restaurantID && params.menuID) {
      (async () => {
        try {
          const docRef = doc(globalState.fireStore, params.restaurantID!, params.menuID!);
          const docSnap = await getDoc(docRef);
          setEpc((docSnap.data() as { epc: string; timestamp: Timestamp }).epc);
        } catch (e) {
          toast.error('Något gick fel. kontrollera URL:en', { autoClose: 3000 });
          console.log('Firebase Error:', e);
        }
      })();
    }
  }, [globalState.fireStore, params.menuID, params.restaurantID]);

  useEffect(() => {
    (async () => {
      if (epc) {
        setLoading(true);
        try {
          const _data = await getFullTraceGraph(epc);
          setData(_data);
          const epcs = _data.nodes.map((n) => n.id);
          if (epcs.length > 0) {
            const _locations = await getLocations(epcs);
            setLocations(_locations);
            console.log('Data & Locations: ', _data, '\n', _locations);
          }
          setLoading(false);
        } catch (e) {
          console.log('Data & Locations FAILED:', e);
          toast.error('Fel QR-kod!', { autoClose: 3000 });
        } finally {
          setLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [epc]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position='static' sx={{ height: '3.5rem' }}>
          <Toolbar>
            <Tooltip title='Go to Home'>
              <IconButton
                color='inherit'
                aria-label='brand'
                sx={{ flexGrow: 1 }}
                onClick={() => {
                  navigate('/', { replace: true });
                }}
              >
                <img src={brand} alt='brand' style={{ height: '1.5rem' }} />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
      </Box>
      {loading && <LinearProgress sx={{ width: '100%', height: '.75rem' }} color='secondary' />}
      <Map loading={loading} data={data} locations={locations} disableOverLay />
    </>
  );
};
export default RestaurantMapScreen;
