import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { Box, CircularProgress, Fab, FormControlLabel, FormGroup } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../context/GlobalState';
import { CustomNavigateOptions, ScanMode } from '../../utils/types';
import Switch from '@mui/material/Switch';

type Props = {
  map: google.maps.Map | null;
  loading: boolean;
  satellite: boolean;
  setSatellite: React.Dispatch<React.SetStateAction<boolean>>;
  disableOverlay?: boolean;
};
const MapOverlay = (props: Props) => {
  const navigate = useNavigate();
  const globalState = useGlobalState();
  const scanButtonDivRef = useRef<HTMLDivElement>(null);
  const toggleButtonDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.map) {
      init(props.map);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.map]);

  const init = (map: google.maps.Map) => {
    const scanButtonDiv = document.createElement('div');
    scanButtonDiv.id = 'map-scan-button-div';

    const toggleButtonDiv = document.createElement('div');
    toggleButtonDiv.id = 'map-toggle-button-div';

    const scanButtonControlDiv = scanButtonDivRef.current;
    const toggleButtonControlDiv = toggleButtonDivRef.current;
    if (!props.disableOverlay) scanButtonDiv.appendChild(scanButtonControlDiv as Node);

    toggleButtonDiv.appendChild(toggleButtonControlDiv as Node);
    map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(scanButtonDiv);
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(toggleButtonDiv);
  };

  const _onFabClick = () => {
    globalState.setScanMode(ScanMode.SINGLE);
    globalState.setMenuMode(undefined);
    navigate('/scanner', { state: { prevPage: '/' }, replace: true } as CustomNavigateOptions);
  };

  return (
    <>
      {!props.disableOverlay && (
        <div id='map-scan-button' ref={scanButtonDivRef}>
          <Fab
            size='large'
            color='primary'
            aria-label='scan'
            sx={{ bottom: '1.5rem' }}
            onClick={_onFabClick}
            disabled={props.loading}
          >
            {props.loading ? (
              <CircularProgress color='secondary' />
            ) : (
              <QrCodeScannerIcon color='inherit' fontSize='large' />
            )}
          </Fab>
        </div>
      )}
      <div id='map-style-toggle' ref={toggleButtonDivRef}>
        <Box
          sx={{
            backgroundColor: 'rgba(255,255,255,0.5)',
            marginTop: '1rem',
            marginLeft: '1.5rem',
            paddingLeft: '1rem',
            borderRadius: '1rem'
          }}
        >
          <FormGroup sx={{}}>
            <FormControlLabel
              control={
                <Switch size='medium' checked={props.satellite} onChange={() => props.setSatellite(!props.satellite)} />
              }
              label={props.satellite ? 'Satellite' : 'Map'}
            />
          </FormGroup>
        </Box>
      </div>
    </>
  );
};

export default MapOverlay;
