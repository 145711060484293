//import { useState } from 'react';
import './ScannerScreen.scss';
import { Box, Button, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { createSearchParams, useNavigate } from 'react-router-dom';
import QrScanner from 'qr-scanner';
import { CustomState, ScanMode } from '../../utils/types';
import { useGlobalState } from '../../context/GlobalState';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import Scanner from './Scanner';
import { toast } from 'react-toastify';
import { retrieveEpc } from '../../utils/HelperFunctions';

const ScannerScreen = () => {
  const globalState = useGlobalState();
  const navigate = useNavigate();

  const _onDecode: (result: QrScanner.ScanResult) => void = (result) => {
    const { scannedItemsRef, setScannedItems, scanModeRef } = globalState;
    const epc = retrieveEpc(result.data);
    if (epc) {
      if (scanModeRef.current === ScanMode.SINGLE) {
        navigate({ pathname: '/', search: createSearchParams({ epc }).toString() }, { replace: true });
      } else {
        if (!scannedItemsRef.current.includes(epc)) {
          toast.success('Ny QR-kod har lagts till', { icon: <QrCode2Icon /> });
          setScannedItems([...scannedItemsRef.current, epc]);
        } else {
          toast.info('QR-kod redan tillagd!', { icon: <QrCode2Icon /> });
        }
      }
    } else {
      toast.warn('Ogiltig URL-adress för QR-kod !');
    }
  };
  return (
    <Box className='scanner-screen-container'>
      <Typography variant='body2' sx={{ color: grey[100], margin: '0 1.5rem 1rem 1.5rem' }} align='center'>
        {globalState.scanMode === ScanMode.SINGLE
          ? 'Håll QR-läsaren över objektet för att spåra produkten.'
          : globalState.scanMode === ScanMode.TRANSFORM
          ? 'Håll QR-läsaren över objekten för att skanna ingående produkter.'
          : ''}
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Scanner onDecode={_onDecode}></Scanner>
      </Box>

      {globalState.scanMode === ScanMode.TRANSFORM && (
        <Button
          variant='contained'
          color='secondary'
          fullWidth
          size='large'
          className='footer'
          onClick={() =>
            navigate('transform', {
              state: {
                prevPage: '/scanner',
                scanMode: ScanMode.TRANSFORM,
                menuMode: globalState.menuMode
              } as CustomState
            })
          }
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant='button'>Klar</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography variant='button' sx={{ fontWeight: 'bold', marginRight: 1 }}>
              {globalState.scannedItems.length}
            </Typography>
            <QrCode2Icon />
          </Box>
        </Button>
      )}
    </Box>
  );
};

export default ScannerScreen;
