import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../context/GlobalState';
import { CustomState, MenuModeText } from '../../utils/types';
import DeleteIcon from '@mui/icons-material/Delete';
import './TransformScreen.scss';
import { useEffect, useState } from 'react';
import { getFullTraceGraph, makeTransformation } from '../../services/API';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { doc, setDoc, Timestamp } from 'firebase/firestore';

const TransformScreen = () => {
  const navigate = useNavigate();
  const globalState = useGlobalState();
  const { scannedItems, menuMode, setScannedItems } = useGlobalState();
  const [loading, setLoading] = useState<boolean>(true);
  const [validItems, setValidItems] = useState<(string | undefined)[]>([]);

  const validateScannedItems = async () => {
    try {
      const result = await Promise.all(
        scannedItems.map(async (item) => {
          try {
            const data = await getFullTraceGraph(item);
            if (data) return item;
          } catch (e) {
            console.log('Validating epc failed:', item, '\n', e);
          }
        })
      );
      setValidItems(result.filter((i) => i));
    } catch (e) {
      console.log('Validating all epcs (Promise.all) failed:', '\n', e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    validateScannedItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transform = async () => {
    if (validItems.length !== scannedItems.length) {
      toast.warn('Makulera artiklar som inte existerar i TracTechnology!', { autoClose: 3000 });
      return;
    }
    setLoading(true);
    try {
      const epc = await makeTransformation(scannedItems, 'menu');

      await setDoc(doc(globalState.fireStore, 'ljuraskolan', 'kottmeny'), {
        epc,
        timestamp: Timestamp.now()
      });
      globalState.setScannedItems([]);
      globalState.setMenuMode(undefined);
      globalState.setScanMode(undefined);
      toast.success('Skapas ✅');
      setLoading(false);
      navigate({ pathname: '/', search: createSearchParams({ epc }).toString() }, { replace: true });
    } catch (e) {
      toast.warn(<p>Misslyckande med att skapa!</p>, { autoClose: 3500 });
      console.log('Transform Error:', e);
      setLoading(false);
    }
  };
  console.log('[TransformScreen] validItems:', validItems);
  return (
    <>
      <Box className='transform-screen-container'>
        <Box sx={{ padding: '1rem' }}>
          <Typography variant='h5' color={grey[100]}>
            Skapa {menuMode ? MenuModeText[menuMode] : 'meny'}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <List sx={{ marginLeft: ' 0.5rem', marginRight: '0.5rem' }}>
            {scannedItems.map((scannedItem, index) => (
              <ListItem
                key={index}
                sx={{ borderBottom: '1px solid #fff', padding: '1rem', cursor: 'pointer' }}
                onClick={() => {
                  navigate(
                    { pathname: '/', search: createSearchParams({ epc: scannedItem }).toString() },
                    { replace: false, state: { prevPage: '/scanner/transform' } as CustomState }
                  );
                }}
                secondaryAction={
                  <IconButton
                    edge='end'
                    aria-label='delete'
                    sx={{ color: grey[100] }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setScannedItems(scannedItems.filter((i) => i !== scannedItem));
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: grey[200] }}>
                    {loading ? (
                      <CircularProgress size={'1.5rem'} color='secondary' />
                    ) : validItems.includes(scannedItem) ? (
                      <CheckIcon fontSize='large' color='success' />
                    ) : (
                      <CloseIcon fontSize='large' color='error' />
                    )}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={scannedItem} sx={{ color: grey[100] }} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
      <Box className='footer' sx={{ backgroundColor: 'primary.main' }}>
        <Button
          variant='contained'
          color='secondary'
          disabled={loading || scannedItems.length === 0}
          fullWidth
          disableElevation
          onClick={() => transform()}
        >
          {loading ? <CircularProgress size={'1.5rem'} sx={{ color: grey[100] }} /> : 'Skapa'}
        </Button>
      </Box>
    </>
  );
};

export default TransformScreen;
