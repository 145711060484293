import { AxiosResponseHeaders } from 'axios';
import { RelativeRoutingType } from 'react-router-dom';

export enum ScanMode {
  TRANSFORM = 'TRANSFORM',
  SINGLE = 'SINGLE'
}

export enum MenuMode {
  MEAT = 'MEAT',
  FISH = 'FISH',
  VEGETARIAN = 'VEGETARIAN'
}

export const MenuModeText: Record<MenuMode, string> = {
  FISH: 'Fiskmeny',
  MEAT: 'Köttmeny',
  VEGETARIAN: 'Veganskmeny'
};

export interface CustomState {
  prevPage?: string;
  scanMode?: ScanMode;
  menuMode?: MenuMode;
}
export interface CustomNavigateOptions {
  replace?: boolean;
  state?: CustomState;
  preventScrollReset?: boolean;
  relative?: RelativeRoutingType;
}
export type pathTo = '/' | '/scanner';
export interface CustomNavigateFunction {
  (to: pathTo, options?: CustomNavigateOptions): void;
  (delta: number): void;
}

export interface Graph {
  edges: [string, string][];
  nodes: { id: string; created: string }[];
}

export type ResponseType<T> = {
  data: T;
  headers?:
    | AxiosResponseHeaders
    | Partial<
        Record<string, string> & {
          'set-cookie'?: string[] | undefined;
        }
      >;
};
export type Location = Record<string, [number, number]>;
export type Locations = {
  reducedLocations: Location;
  allLocations: Location;
};

export enum IDs {
  CU = 'CU',
  C1 = 'C1',
  C2 = 'C2',
  C3 = 'C3',
  C4 = 'C4',
  C5 = 'C5',
  C6 = 'C6',
  C7 = 'C7',
  C8 = 'C8',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  B4 = 'B4',
  B5 = 'B5',
  PO = 'PO',
  PI1 = 'PI1',
  PI2 = 'PI2',
  PI3 = 'PI3',
  P1 = 'P1',
  PP = 'PP',
  MB = 'MB',
  CA = 'CA',
  O = 'O',
  CH = 'CH',
  PA = 'PA',
  E = 'E',
  SL = 'SL',
  PE = 'PE'
}

export const EPCToText: Record<IDs, { what: string; org: string; link?: string }> = {
  B1: { what: 'Nötkött', org: 'Bjursunds Slakteri AB', link: 'http://www.bjursundsslakteri.se/' },
  B2: { what: 'Nötkött', org: 'A J Dahlbergs Slakteri AB', link: 'https://dahlbergskott.se/' },
  B3: { what: 'Nötkött', org: 'Bjursunds Slakteri AB', link: 'http://www.bjursundsslakteri.se/' },
  B4: { what: 'Nötkött', org: 'A J Dahlbergs Slakteri AB', link: 'https://dahlbergskott.se/' },
  B5: { what: 'Nötkött', org: 'Bjursunds Slakteri AB', link: 'http://www.bjursundsslakteri.se/' },
  C1: { what: 'Ko', org: 'Bjursunds Säteri Lantbruks AB' },
  C2: { what: 'Ko', org: 'Murelo AB' },
  C3: { what: 'Ko', org: 'Hökfors Gård' },
  C4: { what: 'Ko', org: 'Norrmem Förvaltning AB' },
  C5: { what: 'Ko', org: 'Årberg Djur AB' },
  C6: { what: 'Ko', org: 'Framnäs Gård' },
  C7: { what: 'Ko', org: 'Karl & Erik Larsson' },
  C8: { what: 'Ko', org: 'Gert Berg' },
  CU: { what: 'Gurka', org: 'Järna Gurkodling AB', link: 'https://jarnagurkan.se/' },
  MB: { what: 'Köttbullar', org: 'Den Svenska Matfabriken AB', link: 'https://svenskamatfabriken.se/' },
  PI1: { what: 'Gris', org: 'Hilmers Lantbruk' },
  PI2: { what: 'Gris', org: 'Hagsgårds Lantbruk' },
  PI3: { what: 'Gris', org: 'Hans Rudell' },
  P1: { what: 'Potatis', org: 'Mårtorps gård' },
  PO: { what: 'Fläskkött', org: 'A J Dahlbergs Slakteri AB', link: 'https://dahlbergskott.se/' },
  PP: { what: 'Potatis', org: 'Skalerian AB', link: 'https://skaleriet.se/index.asp' },
  CA: { what: 'Morot', org: 'Morot' },
  CH: { what: 'Kyckling', org: 'Kyckling' },
  E: { what: 'Ägg', org: 'Hönseri' },
  O: { what: 'Lök', org: 'Lökodlare' },
  PA: { what: 'Packeri', org: 'Packeri' },
  PE: { what: 'Skaleri', org: 'Skaleri' },
  SL: { what: 'Slakteri', org: 'Slakteri' }
};
