import axios, { AxiosRequestConfig } from 'axios';
import { Graph, Location, Locations } from '../utils/types';
import { ObjectEvent, TransformationEvent, TRDType } from './EPICSModels';

const baseUrl = 'https://us-central1-trac-pilot.cloudfunctions.net/';

const getPrefix = (id: string) => id.split('-').slice(0, -1).join('-');

/** API CALLS SNIPPETS */
export const getGraphForVizByEPC = async (epc: string, config?: AxiosRequestConfig): Promise<Graph> => {
  const { data } = await axios.get<Graph>(`${baseUrl}getGraphForVizByEPC`, {
    ...config,
    params: { epc }
  });
  return data;
};

export const getFullTraceGraph = async (epc: string, config?: AxiosRequestConfig): Promise<Graph> => {
  const { data } = await axios.get<Graph>(`${baseUrl}getFullTraceGraph`, {
    ...config,
    params: { epc }
  });
  return { nodes: data.nodes ?? [], edges: data.edges ?? [] };
};

export const getLocations = async (epcs: string[], config?: AxiosRequestConfig): Promise<Locations> => {
  const { data } = await axios.get<Location>(`${baseUrl}getAddLocationsForEpcs`, {
    ...config,
    params: { epcs: epcs.join(',') }
  });

  const reducedLocations = Object.entries(data)
    .sort()
    .reduce((acc, [key, value]) => {
      acc[getPrefix(key)] = value as [number, number];
      return acc;
    }, {} as Location);

  return { reducedLocations, allLocations: data };
};

export const makeTransformation = async (
  epcs: string[],
  trdType: TRDType,
  config?: AxiosRequestConfig
): Promise<string> => {
  const CURRENT_DATE = new Date();
  const TRANSFORM_EPC_ID = epcs[0] + 'transformed';

  const add: ObjectEvent = {
    action: 'ADD',
    trdType: trdType,
    epcList: [TRANSFORM_EPC_ID],
    eventTime: CURRENT_DATE,
    sourceList: [{ location: 'MENU' }],
    destinationList: []
  };

  const transform: TransformationEvent = {
    inputEPCList: epcs,
    outputEPCList: [TRANSFORM_EPC_ID],
    eventTime: CURRENT_DATE,
    sourceList: [{ location: 'MENU' }],
    destinationList: []
  };

  const result = await axios.post<string>(`${baseUrl}sendEvents`, {
    ...config,

    events: [add, transform]
  });
  console.log('Transform sendEvents result:', result);

  return TRANSFORM_EPC_ID;
};
