import { Box, CircularProgress, SxProps, Theme, Typography } from '@mui/material';

const ComponentLoader = (props: { text?: string }) => (
  <Box sx={componentLoaderStyles.fullCentered}>
    <CircularProgress color='secondary' />
    <Typography color={'secondary'}>{props.text ?? 'Loading...'}</Typography>
  </Box>
);

export default ComponentLoader;

export const componentLoaderStyles: { [key: string]: SxProps<Theme> } = {
  fullCentered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: '#000927'
  }
};
