import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Link, useRouteError } from 'react-router-dom';

const ErrorPage = (props: { homeRoute?: string; text?: string }) => {
  const error = useRouteError();
  console.error('ErrorPage Error:', error);
  const errorToText = () => {
    let msg: string = '';
    if ((error as { [key: string]: any }).hasOwnProperty('status')) {
      msg += ` ${(error as { [key: string]: any }).status} `;
    }
    if ((error as Object).hasOwnProperty('statusText')) {
      msg += ` ${(error as { [key: string]: any }).statusText} `;
    }

    return msg;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        width: '100%',
        height: '100vh',
        backgroundColor: 'primary.main'
      }}
      id='error-page'
    >
      <Typography color={grey[100]} variant='h4'>
        Oops!
      </Typography>
      <Typography color={grey[100]} variant='h4'>
        {errorToText()}
      </Typography>
      <Typography color={grey[100]} variant='h4' sx={{ mt: 2 }}>
        <Link to={props.homeRoute ?? '/'} style={{ color: grey[400] }}>
          {props.text ?? 'Go to home'}
        </Link>
      </Typography>
    </Box>
  );
};

export default ErrorPage;
