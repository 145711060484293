import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#000927'
    },
    secondary: {
      main: '#4468E1'
    },
    action: {
      disabledBackground: '#4468E185',
      disabled: grey[400]
    }
  }
});

export default theme;
