import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from '../components/ErrorPage';
import HomeScreen from '../components/home/HomeScreen';
import RestaurantMapScreen from '../components/restaurants/RestaurantMapScreen';
import ScannerScreen from '../components/scanner/ScannerScreen';
import AppShell from '../components/shell/AppShell';
import TransformScreen from '../components/transform/TransformScreen';

const router = createBrowserRouter([
  {
    path: '/',
    element: <AppShell />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <HomeScreen />
      },
      {
        path: 'scanner',
        element: <ScannerScreen />
      },
      {
        path: 'scanner/transform',
        element: <TransformScreen />
      }
    ]
  },
  { path: '/sodertalje/:restaurantID/:menuID', element: <RestaurantMapScreen /> },
  { path: '/norrkoping/:restaurantID/:menuID', element: <RestaurantMapScreen /> }
]);

const AppRoutes = () => {
  return <RouterProvider router={router} />;
};
export default AppRoutes;
